#header {
  display: flex;
  justify-content: space-between;
  padding: 50px;
  font-size: 2rem;

  @include respond-below(md) {
    padding: 25px;
  }

  @include respond-below(sm) {
    padding: 15px;
    font-size: 1.8rem;
  }

  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    @include respond-below(sm) {
      flex-direction: column;
    }
  }

  img {
    width: 200px;

    @include respond-below(sm) {
      width: 125px;
    }
  }

  .links {
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      color: $near-black;
      cursor: pointer;
      margin: 20px;
      font-weight: bold;
      text-decoration: none;
    }

    a:hover {
      color: $brand-blue;
    }
  }
}
