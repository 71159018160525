html {
    margin: 0;
    padding: 0;
    font-size: 62.5%;
    box-sizing: border-box;
    overflow-x: hidden;
}


body {
    margin: 0;
    padding: 0;
    height: 100%;
    opacity: 1;
    transition: 1s opacity;
}