@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;800');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600&display=swap');

body {
    font-family: $body-font;
    color: $near-black;
    font-weight: 400;
    line-height: 1.5;
} 


h1,h2,h3,h4,h5,h6 {
    font-family: $header-font;
    margin: 0;
    font-weight: 400;
}


.page-title {
    h1 {
        margin: 0;
    }

    margin-bottom: 50px;

    @include respond-below(xs) {
        margin-bottom: 25px;
    }
}

p {
    font-family: $body-font;
    font-weight: 400;
    font-size: 1.8rem;
    color: $dark-gray;
    margin-top: 0;
    margin-bottom: 15px;

    @include respond-below(xs) {
        font-size: 1.6rem;
    }
}

h1 { 
    font-size: 6rem; 
    line-height: 1.2;

    @include respond-below(md) {
        font-size: 5rem;
    }

    @include respond-below(sm) {
        font-size: 4rem;
    }

    @include respond-below(xs) {
        font-size: 3rem;
    }
}

h2 { 
    font-size: 4rem; 

    @include respond-below(md) {
        font-size: 3.6rem;
    }

    @include respond-below(sm) {
        font-size: 3rem;
    }

    @include respond-below(xs) {
        font-size: 2.4rem;
    }
}

h3 { 
    font-size: 3.6rem; 

    @include respond-below(md) {
        font-size: 3rem;
    }

    @include respond-below(sm) {
        font-size: 2.4rem;
    }

    @include respond-below(xs) {
        font-size: 2rem;
    }
}


h4 { font-size: 2.4rem; }

h5 { font-size: 2rem; }