#contact {
    min-height: 60vh;
    padding: 10px;

    .content {
        max-width: 1000px;
        margin: 50px auto;
        text-align: center;

        a {
            color: $brand-blue;
            text-decoration: none;
        }    
    }
}