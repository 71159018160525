#theme1 {
  // .theme-button-container {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   flex-direction: row;
  //   justify-content: space-between;
  //   align-items: center;
  //   width: 250px;
  //   height: 50px;
  //   padding: 0;
  //   margin: 0;
  //   border: none;
  //   background-color: transparent;
  //   cursor: pointer;
  // }

  .page-title {
    margin-bottom: 0;
  }

  section {
    padding: 25px;
    max-width: 1000px;
    margin: 0 auto;
  }

  .questions {
    max-width: 1000px;
    margin: 0 auto;
  }

  .question-container {
    // margin-top: 50px;

    margin-bottom: 25px;

    border: 1px solid $white;
    border-radius: 5px;
    // background-color: #fafafa;
    // box-shadow: -0.05em 0 0.2em $accent-purple;

    .question {
      cursor: pointer;
      font-size: 3rem;
      font-weight: bold;
      padding-left: 15px;
      line-height: 1.5;
    }
    .question:hover {
      border-left: 5px solid $brand-blue;
      padding-left: 10px;
      color: $brand-blue;
    }

    .answer {
      // margin-bottom: 50px;
      margin-top: 25px;
      margin-left: 5px;
      padding: 10px 25px;
      border: 1px solid $brand-blue;
      border-radius: 5px;
      background-color: $white;
      font-size: 3rem;
      line-height: 1.5;

      span {
        font-weight: bold;
      }

      ul {
        margin-top: 0px;

        li {
          line-height: 1.5;
          font-size: 2rem;
          color: $color-3-text;
          opacity: 0;
          transition: opacity 0.2s ease 0.2s;
          margin-bottom: 10px;
        }
      }

      p {
        margin-top: 15px;
        line-height: 1.5;
        font-size: 2rem;
        //   color: white;
        opacity: 0;
        transition: opacity 0.2s ease 0.2s;
      }
    }

    .question.active {
      // border-left: 25px solid white !important;
      // border-bottom: 2px solid white;
      color: $brand-blue;
      border-left: 5px solid $brand-blue;
      padding-left: 10px;
      span {
        opacity: 1;
      }
    }

    .answer.active {
      height: auto;
      width: calc(100% - 55px);

      p {
        opacity: 1;
      }
      ul {
        opacity: 1;
        li {
          opacity: 1;
        }
      }
    }
  }
}
#theme2 {
  .theme-button-container {
    position: absolute;
    top: 0;
    left: 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 200;
    height: 80%;
    padding: 0;
    margin: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  .intro {
    padding: 25px;
    max-width: 1000px;
    margin: 0 auto;
    .title {
      font-size: 6rem;

      @include respond-below(md) {
        font-size: 5rem;
      }

      @include respond-below(xs) {
        font-size: 4rem;
      }
    }
  }
  .questions {
    padding: 25px;
    max-width: 1000px;
    margin: 0 auto;

    .question-container {
      // padding: 25px;
      // border: 1px solid #eaeaea;
      // border-radius: 5px;
      // background-color: #fafafa;

      position: relative;
      margin-bottom: 15px;
      border-bottom: 2px solid white;
      transition: border-bottom 0.2s ease;

      .question {
        font-size: 4rem;
        cursor: pointer;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        color: $near-black;
        width: calc(100% - 40px);

        span {
          position: absolute;
          left: -20px;
          opacity: 0;
        }
      }

      .answer {
        //   margin-bottom: 25px;
        //   margin-top: 25px;
        //   padding: 25px;
        //   border: 1px solid #eaeaea;
        //   border-radius: 5px;
        //   background-color: $white;
        //   font-size: 3rem;
        //   line-height: 1.5;

        width: 0;
        height: 0;
        transition: all 0.2s ease, height 0.2 ease 0.2s;
        padding-left: 15px;
        padding-right: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        label {
          font-weight: bold;
          padding: 5px 10px;
          margin-right: 5px;
        }

        ul {
          li {
            line-height: 1.5;
            font-size: 2rem;
            color: $color-3-text;
            opacity: 0;
            transition: opacity 0.2s ease 0.2s;
            margin-bottom: 10px;
          }
        }

        p {
          line-height: 1.5;
          font-size: 2rem;
          //   color: white;
          opacity: 0;
          transition: opacity 0.2s ease 0.2s;
        }

        span {
          font-weight: bold;
        }
      }
    }

    .question.active {
      // border-left: 25px solid white !important;
      // border-bottom: 2px solid white;

      span {
        opacity: 1;
      }
    }

    .answer.active {
      height: auto;
      width: calc(100% - 55px);

      p {
        opacity: 1;
      }
      ul {
        opacity: 1;
        li {
          opacity: 1;
        }
      }
    }

    .question-container:nth-of-type(1) {
      p {
        color: $color-1-text;
      }

      .question {
        border-left: 10px solid $color-1;
      }

      .answer {
        border-left: 10px solid $color-1;
        border-color: $color-1;
        background-color: $color-1;

        label {
          background-color: lighten($color-1, 15%);
        }
      }

      &.active {
        border-color: lighten($color-1, 15%);
        border-left: 25px solid lighten($color-1, 15%);

        .question {
          color: $color-1;
        }
        .answer {
          background-color: $color-1;
        }
      }

      &:hover,
      &.active {
        border-bottom: 2px solid $color-1;
      }
    }

    .question-container:nth-of-type(2) {
      p {
        color: $color-2-text;
      }

      .question {
        border-left: 10px solid $color-2;
      }

      .answer {
        border-left: 10px solid $color-2;
        background-color: $color-2;

        label {
          background-color: lighten($color-2, 15%);
        }
      }

      &.active {
        border-color: lighten($color-2, 15%);
        border-left: 25px solid lighten($color-2, 15%);

        .question {
          color: $color-2;
        }
        .answer {
          background-color: $color-2;
        }
      }

      &:hover,
      &.active {
        border-bottom: 2px solid $color-2;
      }
    }

    .question-container:nth-of-type(3) {
      p {
        color: $color-3-text;
        li {
          color: $color-3-text;
        }
      }

      .question {
        border-left: 10px solid $color-3;
      }

      .answer {
        border-left: 10px solid $color-3;
        background-color: $color-3;

        label {
          background-color: lighten($color-3, 15%);
        }
      }

      &.active {
        border-color: lighten($color-3, 15%);
        border-left: 25px solid lighten($color-3, 15%);

        .question {
          color: $color-3;
        }
        .answer {
          background-color: $color-3;
        }
      }

      &:hover,
      &.active {
        border-bottom: 2px solid $color-3;
      }
    }

    .question-container:nth-of-type(4) {
      p {
        color: $color-4-text;
      }

      .question {
        border-left: 10px solid $color-4;
      }

      .answer {
        border-left: 10px solid $color-4;
        background-color: $color-4;

        label {
          background-color: lighten($color-4, 15%);
        }
      }

      .example {
        border-left: 10px solid lighten($color-4, 15%);
        background-color: lighten($color-4, 15%);
      }

      &.active {
        border-color: lighten($color-4, 15%);
        border-left: 25px solid lighten($color-4, 15%);

        .question {
          color: $color-4;
        }
        .answer {
          background-color: $color-4;
        }
      }

      &:hover,
      &.active {
        border-bottom: 2px solid $color-4;
      }
    }

    .question-container:nth-of-type(5) {
      p {
        color: $color-5-text;
      }

      .question {
        border-left: 10px solid $color-5;
      }
      .answer {
        border-left: 10px solid $color-5;
        background-color: $color-5;

        label {
          background-color: lighten($color-5, 15%);
        }
      }

      .example {
        border-left: 10px solid lighten($color-5, 15%);
        background-color: lighten($color-5, 15%);
      }

      &.active {
        border-color: lighten($color-5, 15%);
        border-left: 25px solid lighten($color-5, 15%);

        .question {
          color: $color-5;
        }
        .answer {
          background-color: $color-5;
        }
      }

      &:hover,
      &.active {
        border-bottom: 2px solid $color-5;
      }
    }

    .question-container:nth-of-type(6) {
      p {
        color: $color-6-text;
      }

      .question {
        border-left: 10px solid $color-6;
      }

      .answer {
        border-left: 10px solid $color-6;
        background-color: $color-6;

        label {
          background-color: lighten($color-6, 15%);
        }
      }

      .example {
        border-left: 10px solid lighten($color-6, 15%);
        background-color: lighten($color-6, 15%);
      }

      &.active {
        border-color: lighten($color-6, 15%);
        border-left: 25px solid lighten($color-6, 15%);

        .question {
          color: $color-6;
        }
        .answer {
          background-color: $color-6;
        }
      }

      &:hover,
      &.active {
        border-bottom: 2px solid $color-6;
      }
    }
  }
}
