$body-font: 'Work Sans', sans-serif;
$header-font: 'Work Sans', sans-serif;

$brand-blue: #4276FF;
$accent-purple: #38366B;



// $color-1: #FF7F51;
// $color-2: #8CD790;
// $color-3: #FF7F51;
// $color-4: #FF7F51;

$light-gray: #EFEFEF;
$white: white;

$near-black: #191919;
$med-gray: #d0d0d0;
$dark-gray: #373737;

$small-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
$medium-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

// $color-1: #577590;
// $color-2: #4d908e;
// $color-3: #43aa8b;
// $color-4: #90be6d;
// $color-5: #f9c74f;
// $color-5-text: $near-black;
// $color-6: #f9844a;
// $color-7:#f8961e;
// $color-8: #f3722c;
// $color-9: #f94144; 

$color-1: #e4572e;
$color-1-text: white;
$color-2: #fe5f55;
$color-2-text: white;
$color-3: #f7b32b;
$color-3-text: $near-black;
$color-4: #13c4a3;
$color-4-text: white;
$color-5: #36f1cd;
$color-5-text: $near-black;
$color-6: #39a0ed;
$color-6-text: white;
$color-7:#4c6085;
$color-7-text: white;
