#view-home {

    section {
        padding: 25px;
        max-width: 1000px;
        margin: 0 auto;
    }

    .text-block {
        margin-bottom: 25px;

        span {
            font-weight: bold;
        }
    }

    .intro {
        position: relative;   
        padding-bottom: 0;   
    }
}