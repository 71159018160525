#login {
    position: absolute;
    top: 25px;
    right: 10px;
    background-color: $brand-blue;
    width: 30%;
    min-width: 300px;
    height: 60vh;
    min-height: 500px;
    z-index: 10;
    box-shadow: $small-shadow;
    padding: 15px;

    .close {
        position: absolute;
        top: 20px;
        right: 20px;
        color: white;
        cursor: pointer;

        svg {
            font-size: 3rem;
            fill: white;
        }
    }

    .form {
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    p {
        margin: 0;
    }

    .form-item {
        width: 75%;
        max-width: 300px;
        margin: 25px auto;
    }

    label {
        display: block;
        color: white;
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 2rem;
    }

    input {
        padding-left: 10px;
        width: calc(100% - 10px);
        display: block;
        height: 40px;
        border: none;
        border-radius: 2px;
        color: $near-black;
        font-size: 2rem;
    }

    input[type="submit"] {
        width: 100%;
        background-color: $near-black;
        color: white;
        font-size: 2rem;
        font-weight: bold;
        cursor: pointer;
    }

    p {
        color: white;
        text-align: center;
    }

    @include respond-below(sm) {
        width: 80%;
    }
}