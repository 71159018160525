#turvo {
  min-height: 60vh;
  padding: 10px;

  .content {
    max-width: 1000px;
    margin: 50px auto;
    text-align: center;

    a {
      color: $brand-blue;
      text-decoration: none;
    }

    @include respond-below(sm) {
      margin: 0 auto;
    }
  }
  .page-subtitle {
    margin: 0 5vw;
  }
  .main-copy {
    text-align: left;
    margin: 2vh 5vw;
  }
  .turvo-logo-inline {
    display: inline;
    vertical-align: middle;
    height: 8rem;
    @include respond-below(md) {
      height: 7rem;
    }

    @include respond-below(sm) {
      height: 6rem;
    }

    @include respond-below(xs) {
      height: 5rem;
    }
  }
}
#turvo-logo {
  margin: 0 3vw;
  padding-bottom: 4px;
}
