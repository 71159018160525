.services {
  svg {
    width: 30px;
    height: 30px;
  }

  .service {
    position: relative;
    margin-bottom: 15px;
    border-bottom: 2px solid white;
    transition: border-bottom 0.2s ease;
  }

  .title {
    cursor: pointer;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: calc(100% - 40px);

    span {
      position: absolute;
      left: -20px;
      opacity: 0;
    }
  }

  .desc {
    width: 0;
    height: 0;
    transition: width 0.2s ease, height 0.2 ease 0.2s;
    padding-left: 5px;
    padding-right: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    label {
      font-weight: bold;
      padding: 5px 10px;
      margin-right: 5px;
    }

    p {
      line-height: 1.5;
      font-size: 2rem;
      color: white;
      opacity: 0;
      transition: opacity 0.2s ease 0.2s;
      margin-top: 15px;

      @include respond-below(xs) {
        font-size: 1.8rem;
      }
    }
    a {
      text-decoration: none;
      font-weight: bold;
      padding: 5px 10px;
      margin-right: 5px;
      color: #191919;
    }
  }

  .title.active {
    // border-left: 25px solid white !important;
    // border-bottom: 2px solid white;

    span {
      opacity: 1;
    }
  }

  .desc.active {
    height: auto;
    width: calc(100% - 55px);

    p {
      opacity: 1;
    }
  }

  .container {
    margin-top: 25px;
  }

  // .service {
  //     @include respond-below(xs) {
  //         .title, .desc {
  //             border-left: 15px !important;
  //         }

  //     }
  // }

  .service:nth-of-type(1) {
    p {
      color: $color-1-text;
    }

    .title {
      border-left: 25px solid $color-1;
    }

    .desc {
      border-left: 25px solid $color-1;
      background-color: $color-1;

      label {
        background-color: lighten($color-1, 15%);
      }
    }

    &.active {
      background-color: lighten($color-1, 15%);

      .title {
        color: $color-1-text;
      }
    }

    &:hover,
    &.active {
      border-bottom: 2px solid $color-1;
    }
  }

  .service:nth-of-type(2) {
    p {
      color: $color-2-text;
    }

    .title {
      border-left: 25px solid $color-2;
    }

    .desc {
      border-left: 25px solid $color-2;
      background-color: $color-2;

      label {
        background-color: lighten($color-2, 15%);
      }
    }

    &.active {
      background-color: lighten($color-2, 15%);

      .title {
        color: $color-2-text;
      }
    }

    &:hover,
    &.active {
      border-bottom: 2px solid $color-2;
    }
  }

  .service:nth-of-type(3) {
    p {
      color: $color-3-text;
    }

    .title {
      border-left: 25px solid $color-3;
    }

    .desc {
      border-left: 25px solid $color-3;
      background-color: $color-3;

      label {
        background-color: lighten($color-3, 15%);
      }
    }

    &.active {
      background-color: lighten($color-3, 15%);

      .title {
        color: $color-3-text;
      }
    }

    &:hover,
    &.active {
      border-bottom: 2px solid $color-3;
    }
  }

  .service:nth-of-type(4) {
    p {
      color: $color-4-text;
    }

    .title {
      border-left: 25px solid $color-4;
    }

    .desc {
      border-left: 25px solid $color-4;
      background-color: $color-4;

      label {
        background-color: lighten($color-4, 15%);
      }
    }

    .example {
      border-left: 25px solid lighten($color-4, 15%);
      background-color: lighten($color-4, 15%);
    }

    &.active {
      background-color: lighten($color-4, 15%);

      .title {
        color: $color-4-text;
      }
    }

    &:hover,
    &.active {
      border-bottom: 2px solid $color-4;
    }
  }

  .service:nth-of-type(5) {
    p {
      color: $color-5-text;
    }

    .title {
      border-left: 25px solid $color-5;
    }
    .desc {
      border-left: 25px solid $color-5;
      background-color: $color-5;

      label {
        background-color: lighten($color-5, 15%);
      }
    }

    .example {
      border-left: 25px solid lighten($color-5, 15%);
      background-color: lighten($color-5, 15%);
    }

    &.active {
      background-color: lighten($color-5, 15%);
    }

    &:hover,
    &.active {
      border-bottom: 2px solid $color-5;
    }
  }

  .service:nth-of-type(6) {
    p {
      color: $color-6-text;
    }

    .title {
      border-left: 25px solid $color-6;
    }

    .desc {
      border-left: 25px solid $color-6;
      background-color: $color-6;

      label {
        background-color: lighten($color-6, 15%);
      }
    }

    .example {
      border-left: 25px solid lighten($color-6, 15%);
      background-color: lighten($color-6, 15%);
    }

    &.active {
      background-color: lighten($color-6, 15%);

      .title {
        color: $color-6-text;
      }
    }

    &:hover,
    &.active {
      border-bottom: 2px solid $color-6;
    }
  }
}
